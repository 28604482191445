import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactModal from 'react-modal';
import { Row, Col } from 'components/shared/Table';
import { COVER_PAGE_BYDATE, MY_CLAIMS_PAGE, LANDING_PAGE, TRIAGE_WRAPPER, REGISTRATION_PAGE, CLAIM_WRAPPER, COVER_PAGE, CHECK_POLICY_COVER, CHECK_CLAIM_COVER_PAGE} from 'constants/constants';
import { useTranslation } from 'react-i18next';
import { goTo } from 'actions/core/app.navigation';
import './_CheckCoverPage.scss';
import { useDispatch } from 'react-redux';
import * as types from 'actions/actionTypes';
import { gotoLandingMainPage } from 'actions/activePageActions';
import { validateField, validateForm } from 'utils/formValidationHelper';
import { schemaValidation } from './CheckCoverFormSchemaValidation';
import { TextField, DateField, Button } from 'components/shared/Forms';
import TriageWrapper from 'containers/TriageWrapper';
import * as activePageActions from 'actions/activePageActions';

export function addAlert(type, msg) {
    return { type: types.ADD_ALERT, alert: { type, msg } };
}
export const CheckClaimCoverByDate = props => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const Title = props.Title;
    const [showPopup, setShowPopup] = useState(false);

    const handleSecondButtonClick = () => {
        props.actions.goTo(COVER_PAGE_BYDATE);
    };
    const [formValues, setFormValues] = useState({
        dateFrom: '' // Initialize dateFrom as an empty string or null
    });
    const [errorFields, setErrorFields] = useState({}); 

    const setNewValues = (fieldName, value) => {
        // Custom logic to set dateFrom to December 31, 2024 if selected date is 2025
       
            // Otherwise, update dateFrom normally
            setFormValues({ ...formValues, [fieldName]: value });
        
    };
    const handleDateChange = (value) => {
        // Update form value
        setFormValues({ ...formValues, dateFrom: value });

        // Check if selected date is in 2025
        if (value && new Date(value).getFullYear() > 2024) {
            setShowPopup(true);
        } else{
            setShowPopup(false);
        }
    };

    const handleSubmit = (event) => {
        debugger;
        const dateFrom = formValues.dateFrom;
        const year2025 = dateFrom && new Date(dateFrom).getFullYear() > 2024;
        event.preventDefault(); // Prevent form from submitting normally
        if (year2025 || dateFrom == "") {
            // If the year is 2025, show popup
            setShowPopup(true);
        } else if (props.Title == 'checkclaim2024') {
            debugger;
            // Otherwise, navigate to the cover page
            //this.actions.activePageActions.gotoCHECK_CLAIM_COVER_PAGE();
           props.actions.gotoCHECK_CLAIM_COVER_PAGE();
        }
        
        //else { props.actions.goTo(COVER_PAGE_BYDATE);}
        else { props.actions.gotoCHECK_USER_PAGE(); }
    };
    const handleValidation = (fieldName, value) => {
        const result = validateField(schemaValidation, fieldName, value);
        setErrorFields({
            ...errorFields,
            ...result.errorFields
        });
    };
    return (
        <div className="div-container">
            <form onSubmit={handleSubmit}>
            <div className="flex-container">
                <div className="flex-item">
                    <div className="flex-item disclaimer">
                        <p>
                            {/*<strong>{t('BenefitCertificateDisclaimerTitle')}</strong>*/}
                                <strong>{t('customerDatePopup')}</strong>
                        </p></div>
                    <DateField                                             
                        name="dateFrom"
                        value={formValues.dateFrom}
                        onValueChange={handleDateChange}
                        errorFields={errorFields}
                        placeholder={t('commonFormDatePlaceholder')}
                        ariaDescribedby={t('commonFormDatePlaceholder')}
                            onValidate={handleValidation}                                                   
                        
                    />
                   
                        <div className="col text-center">
                            <button
                                type="submit"
                                className="btn btn-primary btn-lg mt-3 btn-amex"                               
                            >
                                OK
                            </button>
                        </div>

                    </div>
                </div></form>
                <ReactModal
                    isOpen={showPopup}
                    contentLabel="contentLabel"              
                    className="modal-frame"
                    onRequestClose={() => setShowPopup(false)}
                >
                    <div className="row">
                        <div className="col text-center py-4">
                            <span className="modal-description" dangerouslySetInnerHTML={{
                            __html: (t('customersPopup2025Msz'))
                            }}>
                            </span>
                        </div>
                    </div>
                    <div className="row border-top pt-2 mt-3">
                        <div className="col text-center">
                            <button
                                type="submit"
                            className="btn btn-primary btn-lg mt-3 btn-amex"
                            
                            onClick={() => props.actions.goTo(LANDING_PAGE)}
                            >
                                OK
                            </button>
                        </div>
                    </div>
                </ReactModal>
            </div>                

    );
}
const
    mapDispatchToProps
        = dispatch => ({
            actions
                :
            {
               ... bindActionCreators
                    ({
                        goTo
                    }, dispatch),
                ...bindActionCreators(activePageActions, dispatch)
            }
        });
export
    default
    connect
        (null, mapDispatchToProps)(
            CheckClaimCoverByDate
        );